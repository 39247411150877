.hamburgerMenu {
  position: relative;
  transform: translateY(8px);
  transition: all 0ms 300ms;
}

.hamburgerMenu, .hamburgerMenu::after, .hamburgerMenu::before {
    display: block;
    height: 1.2px;
    width: 28px;
  }

.hamburgerMenu::before, .hamburgerMenu::after {
  content: "";
  position: absolute;
  left: 0;
}

.hamburgerMenu::before {
  bottom: 8px;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburgerMenu::after {
  top: 8px;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburgerMenu.animate {
  background: rgba(0, 0, 0, 0);
}
  
.hamburgerMenu.animate::after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburgerMenu.animate::before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
