@media screen and (min-width: 1024px) {
  .gallery {
    display: grid;

    grid-template-areas: 
      "main2 main2 . . . ."
      "main2 main2 . . main1 main1"
      ". . . . main1 main1";
  }
}

body {
  --yarl__container_background_color: rgb(118 145 242 / 0.3);
  --yarl__color_button_active: #3A3737;
  --yarl__color_button: #3A3737;
  --yarl__icon_size: 40px;
}

.yarl__slide_image {
  border-radius: 20px;
}

.yarl__button:not(:hover) {
  filter: none;
}
